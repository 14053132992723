import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import Access from "../components/access";
import SubTitle from "../components/subtitle";
import PhotoImage from "../components/photo-image";
import { classNames } from "../util/Util";
import styled from "styled-components";

const images = [require("../images/photos/001.jpg")];

const BIG_WIDTH = 800;

const PhotoLayout = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 40px;
  .photo {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
    padding: 24px 16px;
  }
  .photo__big {
    flex-basis: ${BIG_WIDTH}px;
    @media screen and (max-width: ${BIG_WIDTH}px) {
      flex-basis: 300px;
    }
  }
`;

const AccessPage = () => (
  <Layout>
    <Section>
      <Access />
    </Section>
    <Section>
      <SubTitle>駐車場案内</SubTitle>
      <PhotoLayout>
        {images.map((image, index) => {
          return (
            <PhotoImage
              src={image}
              caption="患者さん用駐車場20台ご用意しております"
              wrapperClassName={classNames({
                photo__big: true,
                photo: true,
              })}
              key={index}
            />
          );
        })}
      </PhotoLayout>
      <SubTitle>ストリートビュー</SubTitle>
      <p>
        医院周辺の様子を見ることができます。お越しになる際のご参考になれば幸いです。
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!4v1579511262973!6m8!1m7!1sCAoSLEFGMVFpcE5WM3gtaVhUY3dYOW5Xd3ZwQnhTcnhYTnJXbWhNOTFPMnZGRV9i!2m2!1d33.216640763508!2d130.55869094235!3f185.38617080652244!4f-11.503795192475451!5f0.4000000000000002"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen={false}
      />
    </Section>
  </Layout>
);

export default () => <AccessPage />;
